import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"

const About = () => {
  const dateBecameDev = new Date("2017-06-06")
  const today = new Date()
  const yearsAsDev = today.getFullYear() - dateBecameDev.getFullYear()

  return (
    <Layout>
      <Seo title="About" />
      <PageTitle>About Me</PageTitle>
      <section className="content">
        <div className="flex flex-col md:flex-row mb-8">
          <div
            className="image-wrapper mr-6 mb-6 md:mb-0"
            style={{ maxWidth: `180px` }}
          >
            <StaticImage
              src="../images/jefetemeso.jpg"
              width={300}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A picture of Jeff"
            />
          </div>

          <div>
            <p className="mb-8">
              Hello there. I’m a web developer with {yearsAsDev} years of
              experience. In that time I've had to learn new stacks and maintain
              old ones. It's a never ending project which I love.
            </p>

            <p className="mb-8">
              l enjoy tinkering with Linux machines and run a server where I
              host my projects, dive deep into security, and play with new
              tools. I have a keen eye for design and I'm not happy until every
              little detail looks right.
            </p>

            <p className="mb-0">
              When I’m not on my computer I’m traveling, pluckin the bass, or
              playing hockey.
            </p>
          </div>
        </div>

        <br />
        <h3>Current tools</h3>
        <ul className="list-disc">
          <li>
            <strong>Laptop: </strong>
            <a
              href="https://www.lenovo.com/ca/en/laptops/thinkpad/thinkpad-t-series/ThinkPad-T470s/p/22TP2TT470S"
              target="_blank"
              rel="noopener noreferrer"
            >
              ThinkPad T470s
            </a>
          </li>
          <li>
            <strong>OS: </strong>
            <a
              href="https://getfedora.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fedora 41
            </a>
          </li>
          <li>
            <strong>Frameworks/Libraries: </strong>
            <a
              href="https://svelte.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Svelte
            </a>
            <span>, </span>
            <a
              href="https://nextjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Next.js
            </a>
            <span>/</span>
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            <span>, & learning </span>
            <a
              href="https://elm-lang.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Elm
            </a>
          </li>
          <li>
            <strong>Editor: </strong>
            <a
              href="https://www.lazyvim.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Neovim (LazyVim)
            </a>
          </li>
          <li>
            <strong>Theme: </strong>
            <a
              href="https://github.com/sainnhe/everforest"
              target="_blank"
              rel="noopener noreferrer"
            >
              Everforest
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default About
